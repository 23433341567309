export const NAME: string = 'Sticker'

export type StickerTheme = {
    showOneKronaSticker: boolean
    stickers: {
        se: string
        at?: string
        ch?: string
        nl?: string
        no?: string
        fr?: string
        de?: string
        dk?: string
        be?: string
        fi?: string
        default: string
    }
}
